import React from "react";
import styled from "styled-components";
import { useTranslations } from "next-intl";

import { breakpoint } from "modules/DesignSystem/breakpoint";

const Wrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 16px;
  background: #fff;
  box-shadow: 0px 20px 20px 0px #0000000a;
  text-align: center;
  border-radius: 8px;

  @media (min-width: ${breakpoint.laptop}) {
    margin-top: 0;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #989898;
  margin: 0 auto;

  @media (min-width: ${breakpoint.laptop}) {
    width: 180px;
  }
`;

export const NotAvailable = () => {
  const t = useTranslations();

  return (
    <Wrapper>
      <Paragraph>{t("product.not_available")}</Paragraph>
    </Wrapper>
  );
};
