import styled from "styled-components";

import Link from "components/link";
import { breakpoint } from "modules/DesignSystem/breakpoint";

export const Cover = styled.img`
  border-radius: 4px 4px 0 0;
  display: flex;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

export const Title = styled.h1`
  color: #fff;
  font-weight: 700;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  @media (min-width: ${breakpoint.laptop}) {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 8px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  justify-content: space-between;

  @media (min-width: ${breakpoint.laptop}) {
    justify-content: flex-start;
  }
`;

export const Tag = styled(Link)`
  align-items: center;
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 16px;
  line-height: 20px;
  padding: 3px 5px;
  text-decoration: none;

  &[href] {
    transition: color 0.25s ease, background-color 0.25s ease;

    &:hover {
      border-color: rgba(51, 173, 255, 0.2);
      color: #33adff;
    }
  }
`;
