import React from "react";
import styled, { css } from "styled-components";
import { useTranslations } from "next-intl";

import { breakpoint } from "modules/DesignSystem/breakpoint";
import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import { Audiobook } from "resources/AudiotekaApi";

import { Collections } from "./Collections";
import { Details } from "./Details/Details";

const Content = styled.div<{ isVisible: boolean }>`
  display: grid;
  grid-template-rows: ${({ isVisible }) => (isVisible ? "1fr" : "0fr")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: grid-template-rows 300ms, opacity 600ms 200ms;

  & > * {
    overflow: hidden;
  }

  @media (min-width: ${breakpoint.laptop}) {
    flex: 1;
    display: block;
    transition: none;
    opacity: 1;

    & > * {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Button = styled(ButtonPrimitive)<{ open: boolean }>`
  color: #fff;
  border-top: solid 1px rgba(187, 183, 197, 0.3);
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  padding: 12px 0;
  text-align: left;

  &::after {
    border: solid #bbb7c5;
    border-width: 0 2px 2px 0;
    content: "";
    display: block;
    height: 10px;
    margin-right: 3px;
    transition: transform 0.2s ease;
    width: 10px;
  }

  ${({ open }) =>
    open
      ? css`
          &::after {
            transform: translateY(25%) rotateZ(-135deg);
          }
        `
      : css`
          &::after {
            transform: translateY(-25%) rotateZ(45deg);
          }
        `}

  @media (min-width: ${breakpoint.laptop}) {
    display: none;
  }
`;

type Props = {
  audiobook: Audiobook;
  available: boolean;
};

export const MoreInfo = ({ audiobook, available }: Props) => {
  const t = useTranslations();

  const [moreInfoVisible, setMoreInfoVisible] = React.useState(false);

  const toggleMoreInfo = () => {
    setMoreInfoVisible((visible) => !visible);
  };

  return (
    <>
      <Button open={moreInfoVisible} onClick={toggleMoreInfo}>
        {t("product.more_info")}
      </Button>
      <Content isVisible={moreInfoVisible}>
        <div>
          <Details audiobook={audiobook} />
          {available ? <Collections audiobook={audiobook} /> : null}
        </div>
      </Content>
    </>
  );
};
