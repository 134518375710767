import styled from "styled-components";

const elevationMap = {
  0: "initial",
  1: "0px 2px 5px 0px rgba(0, 0, 0, 0.2)",
};

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  border-radius: 4px;
  overflow: hidden;
  box-shadow: ${(props) => elevationMap[props.theme.elevation] || "initial"};
`;
