import React from "react";
import { Container } from "./styled";
import { PaperProps } from "./types";

const Paper: React.FC<PaperProps> = function Paper({ children, elevation, className }) {
  return (
    <Container className={className} theme={{ elevation }}>
      {children}
    </Container>
  );
};

export default Paper;
