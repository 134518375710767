import styled from "styled-components";

import { breakpoint } from "modules/DesignSystem/breakpoint";
import Alert from "modules/DesignSystem/components/Alert";
import Button from "modules/DesignSystem/components/Button";

export const CannotRate = styled.p<{ inactive?: boolean }>`
  color: ${({ inactive }) => (inactive ? "#989898" : "#000")};
  font-size: ${({ inactive }) => (inactive ? "12px" : "14px")};
  flex-basis: 100%;
  margin: 0;

  a {
    color: #33adff;
    font-weight: 700;
  }

  @media (min-width: ${breakpoint.tablet}) {
    flex-basis: 330px;
    text-align: right;
  }
`;

export const Container = styled.div`
  padding-bottom: 24px;

  @media (min-width: ${breakpoint.tablet}) {
    padding-right: 16px;
  }
`;

export const EditBtn = styled(Button)`
  align-self: flex-end;
  padding: 0;
`;

export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin: 16px 0 24px;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 26px;
    margin: 32px 0;
  }
`;

export const Review = styled.div`
  display: flex;
  flex-direction: column;

  ${SubTitle} {
    margin-bottom: 16px;
  }

  ${Alert} {
    margin-bottom: 24px;
  }
`;

export const Title = styled.p`
  font-size: 26px;
  font-weight: 700;
  margin: 16px 0;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 32px;
    margin: 24px 0;
  }
`;

export const Top = styled.div`
  border: solid #bbb7c5;
  border-width: 1px 0;
  display: grid;
  flex-direction: column;
  grid-gap: 16px;
  grid-template-columns: 1fr 192px;
  justify-content: space-between;
  padding: 24px 0;

  @media (min-width: ${breakpoint.tablet}) {
    align-items: center;
    grid-template-columns: 1fr 343px;
  }
`;
