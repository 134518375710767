import styled from "styled-components";
import { breakpoint } from "modules/DesignSystem/breakpoint";

export const BadgeContent = styled.div`
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .rating-badge__rate {
    width: 40%;
    height: 40%;
  }
`;

export const BadgeWrapper = styled.div`
  color: #160829;
  font-size: 14px;
  height: 42px;
  position: relative;
  width: 42px;

  svg {
    height: 100%;
    width: 100%;
  }

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 16px;
    height: 58px;
    width: 58px;
  }
`;

export const Root = styled.div`
  align-items: center;
  display: flex;
  grid-gap: 16px;
`;

export const Text = styled.p`
  margin: 0;
`;
