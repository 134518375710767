import React from "react";
import styled from "styled-components";
import { useTranslations } from "next-intl";

import { Audiobook } from "resources/AudiotekaApi";
import { breakpoint } from "modules/DesignSystem/breakpoint";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { NewIcon } from "components/NewIcon";

import { Tag } from "./ProductTop.styled";

const Description = styled.p`
  margin: 0 0 8px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
`;

const CollectionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;

  @media (min-width: ${breakpoint.laptop}) {
    align-self: start;
    margin-top: auto;
  }

  svg {
    margin-right: 4px;
  }
`;

export const Collections = ({ audiobook }: { audiobook: Audiobook }) => {
  const t = useTranslations();

  const collections = audiobook._embedded["app:contained-in"];

  if (collections.length === 0) {
    return null;
  }

  return (
    <div>
      <Description>{t("product.collection_description")}:</Description>
      <CollectionList>
        {collections.map((cycle) => (
          <Tag {...deeplinkTranslator(cycle.deeplink, cycle.slug)} key={cycle.id}>
            <NewIcon icon="cycle" width="12" height="12" />
            {cycle.name}
          </Tag>
        ))}
      </CollectionList>
    </div>
  );
};
