import React from "react";
import { useTranslations } from "next-intl";

import { Link } from "components";
import { Audiobook } from "resources/AudiotekaApi";
import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";

import RatingBadge from "../../RatingBadge";
import { AddRateBtn } from "../styled";

import { CannotRate, Title, Top } from "./styled";

interface Props {
  canRate: boolean;
  isPreLogged: boolean;
  audiobook: Audiobook;
  onRate: () => void;
}

const RatingOptions = ({ canRate, isPreLogged, onRate }: Omit<Props, "audiobook">) => {
  const t = useTranslations();
  const { signInRedirectParams } = useSignInRedirectParams();

  if (canRate) {
    return <AddRateBtn onClick={onRate}>{t("product.reviews.add_rating")}</AddRateBtn>;
  }

  if (isPreLogged) {
    return <CannotRate inactive>{t("product.reviews.buy_to_rate")}</CannotRate>;
  }

  return (
    <CannotRate>
      {t.rich("product.reviews.login_to_rate", {
        // eslint-disable-next-line react/no-unstable-nested-components
        link1: (chunks) => (
          <Link route="signIn" params={signInRedirectParams}>
            {chunks}
          </Link>
        ),
      })}
    </CannotRate>
  );
};

const Header = ({ canRate, isPreLogged, audiobook, onRate }: Props) => {
  const t = useTranslations();

  const ratingCount = audiobook.rating_count;
  const rating = Math.round(audiobook.rating * 10) / 10;

  return (
    <>
      <Title>{t("product.reviews.all_title")}</Title>
      <Top>
        <RatingBadge average={rating} count={ratingCount} />
        <RatingOptions canRate={canRate} isPreLogged={isPreLogged} onRate={onRate} />
      </Top>
    </>
  );
};

export default Header;
