import styled, { css, keyframes } from "styled-components";
import { SpinnerColor } from "./index";

const spin = keyframes`
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const Path = styled.path`
  transform-origin: center;
`;

export const FirstPath = styled(Path)`
  animation: ${spin} 1s linear infinite;
`;

export const SecondPath = styled(Path)`
  animation: ${spin} 0.75s linear reverse infinite;
`;

export const ThirdPath = styled(Path)`
  animation: ${spin} 0.5s linear infinite;
`;

export const Svg = styled.svg<{ color: SpinnerColor; size: number }>`
  display: block;
  fill: none;
  height: ${({ size }) => size}px;
  margin: 0 auto;
  stroke-linecap: round;
  stroke-width: 2px;
  width: ${({ size }) => size}px;

  ${({ color }) =>
    color === "tricolor"
      ? css`
          ${FirstPath} {
            stroke: #4dffc5;
          }

          ${SecondPath} {
            stroke: #33adff;
          }

          ${ThirdPath} {
            stroke: #d619ff;
          }
        `
      : css`
          stroke: ${color};
        `}
`;
