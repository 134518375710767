import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  & + & {
    margin-top: 24px;
  }
`;
