import React, { useEffect, useState } from "react";
import { useTranslations } from "next-intl";

import { ButtonVariant } from "modules/DesignSystem/components/Button";
import { Audiobook, DownloadableAudiobook, audiobookDownloadCommands } from "resources/AudiotekaApi";

import { Icon, ActionBtn, Box, Title } from "../Boxes.styled";
import { InfoIcon } from "../InfoIcon";
import { DownloadBox } from "./Download/DownloadBox";
import { PlayButton } from "../Buttons";

interface Props {
  audiobook: Audiobook;
  canDownload: boolean;
}

export const ProductOnShelf = ({ audiobook, canDownload }: Props) => {
  const t = useTranslations();

  const [mp3Download, setMp3Download] = useState<DownloadableAudiobook | null>(null);

  useEffect(() => {
    async function downloadAudiobook() {
      try {
        const downloadableAudiobook = await audiobookDownloadCommands(audiobook.id);
        setMp3Download(downloadableAudiobook);
      } catch {
        setMp3Download(null);
      }
    }
    if (canDownload) {
      downloadAudiobook();
    } else {
      setMp3Download(null);
    }
  }, [canDownload, audiobook.id]);

  return (
    <>
      <Box>
        <Title>
          <Icon icon="shelf" width="24" height="24" />
          {t("product.price_box.title.shelf")}
          {mp3Download && <InfoIcon info={t("product.price_box.info.shelf_mp3")} />}
        </Title>
        <PlayButton audiobook={audiobook} />
        <ActionBtn variant={ButtonVariant.outlined} route="shelf">
          <Icon icon="shelf" width="20" height="20" />
          {t("product.price_box.cta.shelf")}
        </ActionBtn>
      </Box>
      {mp3Download && <DownloadBox audiobook={audiobook} mp3={mp3Download} />}
    </>
  );
};
