import React from "react";
import { useTranslations } from "next-intl";

import { NewIcon } from "components/NewIcon";

import { BadgeContent, BadgeWrapper, Root, Text } from "./styled";

const RatingBadge = ({ average, count }: { average: number; count: number }) => {
  const t = useTranslations();

  return (
    <Root>
      <BadgeWrapper>
        <NewIcon icon="badge" width="24" height="24" />
        <BadgeContent>
          {count > 0 ? average : <NewIcon className="rating-badge__rate" icon="rate" width="24" height="24" />}
        </BadgeContent>
      </BadgeWrapper>
      <Text>{count > 0 ? t("product.reviews.average", { count }) : t("product.reviews.no_rates")}</Text>
    </Root>
  );
};

export default RatingBadge;
