import { darkStrongError, darkStrongAdditional } from "../../colors";
import { AlertType } from "./types";

/* eslint-disable import/prefer-default-export */
export const ALERT_THEME = {
  [AlertType.Info]: {
    color: "white",
    background: darkStrongAdditional,
  },
  [AlertType.Warning]: {
    color: "white",
    background: darkStrongError,
  },
};
