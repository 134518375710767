import React, { useEffect, useRef } from "react";

import { NewIcon } from "components/NewIcon";
import Link from "components/link";

import { Item, List, Root, Separator } from "./BreadCrumbs.styled";
import { BreadCrumbsItem } from "./types";

interface Props {
  items: BreadCrumbsItem[];
}

const BreadCrumbs = ({ items }: Props) => {
  const listRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo({ behavior: "auto", left: listRef.current.scrollWidth });
    }
  }, [items]);

  if (items.length === 0) {
    return null;
  }

  return (
    <Root>
      <List ref={listRef}>
        <Item as={Link} route="home">
          <NewIcon icon="home" width="18" height="18" />
        </Item>

        {items.map((item: BreadCrumbsItem) => (
          <React.Fragment key={item.name}>
            <Separator name="arrow" size={14} />
            {item.link ? (
              <Item as={Link} {...item.link}>
                {item.name}
              </Item>
            ) : (
              <Item>{item.name}</Item>
            )}
          </React.Fragment>
        ))}
      </List>
    </Root>
  );
};

export default BreadCrumbs;
