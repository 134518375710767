export const AlertType = {
  Info: "info",
  Warning: "warning",
};

export interface AlertProps {
  alertType?: ObjectValues<typeof AlertType>;
  elevation?: number;
  message?: string;
  centered?: boolean;
  /** margin-bottom in rem */
  mb?: number;
  /** margin-top in rem */
  mt?: number;
}
