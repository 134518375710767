import styled from "styled-components";
import Icon from "modules/DesignSystem/components/Icon/Icon";
import Container from "modules/DesignSystem/components/Container";
import { lightStrongAdditional } from "modules/DesignSystem/colors";

export const List = styled(Container)`
  align-items: stretch;
  color: #fff;
  column-gap: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0;

  &::-webkit-scrollbar {
    height: 0;
  }
`;

export const Item = styled.span`
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 16px;
  line-height: 38px;
  padding: 0 5px;
  white-space: nowrap;

  &:first-child {
    margin-left: 10px;
  }

  &:last-child {
    margin-right: 10px;
  }

  a& {
    color: ${lightStrongAdditional};

    &:hover {
      color: #fff;
    }

    &:active {
      background: ${lightStrongAdditional};
    }
  }
`;

export const Root = styled.div`
  background: #24173f;
`;

export const Separator = styled(Icon)`
  align-self: center;
  color: #8d889e;
  min-width: 14px;
`;
