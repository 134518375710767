import React from "react";
import dynamic from "next/dynamic";

import SlideBar from "modules/DesignSystem/components/SlideBar";
import Spinner from "modules/DesignSystem/components/Spinner";
import { Audiobook, ProductReview } from "resources/AudiotekaApi";

import { SpinerWrapper } from "../styled";

const Content = dynamic(() => import("./Content"), {
  ssr: false,
  loading: () => (
    <SpinerWrapper>
      <Spinner />
    </SpinerWrapper>
  ),
});

interface Props extends Pick<React.ComponentProps<typeof SlideBar>, "onClose" | "open"> {
  onReview(review: ProductReview): void;
  audiobook: Audiobook;
  review: ProductReview | null;
}

const ReviewFormSlideBar = ({ onClose, onReview, open, audiobook, review: existingReview }: Props) => {
  return (
    <SlideBar onClose={onClose} open={open}>
      {open && <Content audiobook={audiobook} open={open} review={existingReview} onReview={onReview} />}
    </SlideBar>
  );
};

export default ReviewFormSlideBar;
