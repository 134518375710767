import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";

import Title from "modules/DesignSystem/components/Title";
import { useUser } from "modules/user";
import Alert from "modules/DesignSystem/components/Alert";
import { Grid } from "components";
import { HtmlToReact } from "components/html-to-react";
import {
  AttachmentList as AttachmentListType,
  Audiobook as AudiobookType,
  getLicensesChannels,
  LicenseChannels,
  ProductReviewList,
} from "resources/AudiotekaApi";

import ReviewsBox from "./components/ReviewsBox";
import { NotAvailable, PriceBox } from "./components/PriceBox";
import { PriceBoxDesktop } from "./components/PriceBoxDesktop";
import { ProductTop } from "./components/ProductTop";
import { useAnalytics } from "./useAnalytics";
import {
  Content,
  Description,
  DescriptionMore,
  Details,
  Main,
  PriceContainer,
  SectionSeparator,
  Wrapper,
} from "./Audiobook.styled";

const AttachmentList = dynamic(() => import("./components/AttachmentList"), { ssr: false });

interface Props {
  attachmentList: AttachmentListType | null;
  audiobook: AudiobookType;
  darkMode?: boolean;
  linkReplace: string | null;
  reviews: ProductReviewList;
  currency: string;
}

export const Audiobook = ({ attachmentList, audiobook, darkMode, linkReplace, reviews, currency }: Props) => {
  const reviewsRef = useRef<HTMLDivElement>();
  const mainRef = useRef<HTMLDivElement>();

  const { isLoading: isLoadingUser, isLoggedIn, isClubMember } = useUser();

  const t = useTranslations();

  const [isDescriptionMore, setDescriptionMore] = useState(false);
  const [licenses, setLicenses] = useState<LicenseChannels | undefined>(undefined);

  useAnalytics(audiobook, currency);

  const [canListen, canRate] = useMemo((): [boolean, boolean] => {
    if (!licenses) {
      return [false, false];
    }

    const licenseStates = Object.values(licenses).map((license) => license.state);
    const _canListen = licenseStates.includes("CAN_LISTEN");

    return [_canListen, _canListen || licenseStates.includes("NOT_AVAILABLE_ON_THIS_DEVICE")];
  }, [licenses]);

  const isAvailable =
    canListen ||
    (audiobook._embedded["app:context"].is_enabled &&
      (!!audiobook.price ||
        !!audiobook.price_for_subscribers ||
        !!audiobook.is_available_in_subscription ||
        audiobook.is_free));

  useEffect(() => {
    if (linkReplace && window.location.pathname !== linkReplace) {
      window.history.replaceState(window.history.state, window.document.title, linkReplace);
    }
  }, [linkReplace]);

  useEffect(() => {
    async function loadLicensesChannels() {
      try {
        const licensesChannels = await getLicensesChannels(audiobook.id);
        setLicenses(licensesChannels);
      } catch {
        setLicenses(undefined);
      }
    }

    if (isLoadingUser) {
      return;
    }

    if (isLoggedIn) {
      loadLicensesChannels();
    } else {
      setLicenses(undefined);
    }
  }, [isLoadingUser, isLoggedIn, audiobook]);

  const scrollToReviews = useCallback(() => {
    if (reviewsRef.current) {
      window.scrollBy({
        behavior: "smooth",
        top: reviewsRef.current.getBoundingClientRect().top - 70,
      });
    }
  }, [reviewsRef.current]);

  const priceBox = isAvailable ? (
    <PriceBox audiobook={audiobook} licenses={licenses} isClubMember={isClubMember} />
  ) : (
    <NotAvailable />
  );

  const alert = audiobook.web_alert || audiobook.alert;

  return (
    <Wrapper>
      <PriceBoxDesktop mainRef={mainRef}>{priceBox}</PriceBoxDesktop>
      <Main blur={!darkMode} dark={darkMode} img={`${audiobook.image_url}?w=300&auto=format`} ref={mainRef}>
        <ProductTop audiobook={audiobook} available={isAvailable} canListen={canListen} onReviews={scrollToReviews} />
        <Content dark={darkMode}>
          <Grid.Container>
            <Grid.Row>
              <Grid.Column span={{ tablet: 6 }} $offset={{ tablet: 4 }}>
                <PriceContainer>{priceBox}</PriceContainer>
              </Grid.Column>
              <Grid.Column span={{ laptop: 8 }}>
                <Details>
                  {alert && (
                    <Alert centered mb={2}>
                      <HtmlToReact html={alert} />
                    </Alert>
                  )}
                  <Title first>{t("product.description")}</Title>
                  {audiobook ? (
                    <Description
                      dangerouslySetInnerHTML={{ __html: audiobook.description_html }}
                      more={isDescriptionMore}
                    />
                  ) : (
                    <Description more={isDescriptionMore}>{audiobook.description}</Description>
                  )}
                  <DescriptionMore onClick={() => setDescriptionMore((more) => !more)} more={isDescriptionMore}>
                    {isDescriptionMore ? t("product.description_less") : t("product.description_more")}
                  </DescriptionMore>
                  {attachmentList && canListen && <AttachmentList list={attachmentList} />}
                  <SectionSeparator />
                  <ReviewsBox
                    canRate={canRate}
                    isPreLogged={isLoggedIn}
                    audiobook={audiobook}
                    ref={reviewsRef}
                    reviews={reviews}
                  />
                </Details>
              </Grid.Column>
            </Grid.Row>
          </Grid.Container>
        </Content>
      </Main>
    </Wrapper>
  );
};
