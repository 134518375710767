import styled from "styled-components";

import ButtonPrimitive from "modules/DesignSystem/components/ButtonPrimitive";
import { breakpoint } from "modules/DesignSystem/breakpoint";

export const Button = styled(ButtonPrimitive)`
  background: rgba(255, 255, 255, 0.25);
  border-radius: 0 0 4px 4px;
  min-height: 56px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  text-transform: none;
  padding: 8px 16px;
  width: 100%;
  --icon-size: 32px;
  --icon-scale: 1;

  &:hover {
    --icon-scale: 1.1;
  }

  svg {
    height: var(--icon-size);
    width: var(--icon-size);
    transition: transform 0.5s ease;
    transform: scale(var(--icon-scale));
  }

  @media (min-width: ${breakpoint.tablet}) {
    --icon-size: 40px;
  }

  @media (min-width: ${breakpoint.desktop}) {
    --icon-size: 48px;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.span`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;

export const Sample = styled.span`
  font-size: 12px;
  line-height: 16px;
`;
