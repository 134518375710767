import React from "react";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";

import { getPrices } from "../../utils";
import { FreeProduct } from "./Boxes/FreeProduct";
import { ProductOnShelf } from "./Boxes/ProductOnShelf/ProductOnShelf";
import { ClubMember, ClubOnlyProduct } from "./Boxes/Club";
import { ProductToBuy } from "./Boxes/ProductToBuy";
import { PremiumProduct } from "./Boxes/PremiumProduct";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  isClubMember?: boolean;
};

export const PriceBox = ({ audiobook, licenses, isClubMember }: Props) => {
  const { is_available_in_subscription: clubListen } = audiobook;

  const price = getPrices(audiobook, { data: licenses }, isClubMember).withCurrency;

  const isOnShelf = licenses?.oneoff.state === "CAN_LISTEN" || licenses?.shared.state === "CAN_LISTEN";
  const canBuy = !!price.base;

  if (audiobook.is_free) {
    // Free product
    return <FreeProduct audiobook={audiobook} />;
  }
  if (isOnShelf) {
    // Already on shelf
    return <ProductOnShelf audiobook={audiobook} canDownload={licenses?.oneoff.is_downloadable || false} />;
  }
  if (clubListen) {
    // User can listen with club
    if (isClubMember) {
      return <ClubMember audiobook={audiobook} licenses={licenses} price={price} />;
    }
    // Club only. Cannot buy
    if (!canBuy) {
      return <ClubOnlyProduct />;
    }
  }

  if (canBuy) {
    const isPremium = audiobook._embedded["app:context"].is_premium;

    if (isPremium) {
      return <PremiumProduct audiobook={audiobook} licenses={licenses} price={price} />;
    }

    return (
      <ProductToBuy
        audiobook={audiobook}
        licenses={licenses}
        price={price}
        isClubMember={isClubMember}
        clubListen={clubListen}
      />
    );
  }

  return null;
};
