import styled, { css } from "styled-components";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div<{ visible?: boolean }>`
  background: #fff;
  border: 1px solid #bbb7c5;
  border-radius: 8px;
  color: #989898;
  font-size: 14px;
  left: 0;
  max-width: 175px;
  padding: 8px;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  width: calc(100% - 30px);
  z-index: 100;

  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  @media screen and ${MEDIA_QUERY.md} {
    font-size: 12px;
  }
`;
