import React, { ComponentProps } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import * as Sentry from "@sentry/nextjs";
import { JSONSchema7Object } from "json-schema";

import { buildLink } from "utils/buildLink";
import { isId, parsePrice } from "utils/string";
import { cutText, getCategoryFromCatalog } from "modules/Audiobook/utils";
import { Audiobook } from "modules/Audiobook";
import BreadCrumbs from "components/BreadCrumbs";
import { BreadCrumbsItem } from "components/BreadCrumbs/types";
import { getLocationInfo } from "utils/getLocationInfo";
import {
  Audiobook as AudiobookType,
  Catalog,
  Category,
  getAttachmentList,
  getAudiobook,
  getProductReviewList,
  getScreenCatalog,
} from "resources/AudiotekaApi";

type Props = ComponentProps<typeof Audiobook> & { categoryName: string; breadcrumbs: BreadCrumbsItem[] };

const getNames = (audiobook: AudiobookType, key: "author" | "publisher" | "lector") =>
  audiobook._embedded[`app:${key}`].map((data) => data.name).join(", ");

export default function AudiobookPage({
  attachmentList,
  audiobook,
  breadcrumbs,
  categoryName,
  currency,
  darkMode,
  linkReplace,
  reviews,
}: Props) {
  const t = useTranslations();
  const { asPath } = useRouter();

  const authors = getNames(audiobook, "author");
  const publishers = getNames(audiobook, "publisher");

  const schema: JSONSchema7Object = {
    "@context": "http://schema.org",
    "@type": "Audiobook",
    name: audiobook.name,
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: audiobook.rating || 0,
      ratingCount: audiobook.rating_count,
    },
    offers: audiobook.price
      ? {
          "@type": "Offer",
          price: parsePrice(audiobook.price),
          priceCurrency: currency,
        }
      : undefined,
    author: authors,
    publisher: publishers,
    readBy: getNames(audiobook, "lector"),
    datePublished: audiobook.published_at,
  };

  return (
    <>
      <Head>
        <title>{t("meta.product.title", { product: audiobook.name, publisher: publishers })}</title>
        <meta
          name="description"
          content={t("meta.product.description", {
            product: audiobook.name,
            author: authors,
            category: categoryName,
          })}
        />
        <meta name="keywords" content={authors} />

        <meta property="og:url" content={`${process.env.SITE_URL}${asPath}`} />
        <meta key="og-title" property="og:title" content={`${audiobook.name} - ${authors} | Audioteka`} />
        <meta key="og-description" property="og:description" content={cutText(audiobook.description || "", 50)} />
        <meta key="og-image" property="og:image" content={`${audiobook.image_url}?auto=format&w=500`} />
        <meta property="og:site_name" content="Audioteka" />
        <meta property="og:type" content="Audiobook" />

        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
      </Head>
      <BreadCrumbs items={breadcrumbs} />
      <Audiobook
        attachmentList={attachmentList}
        audiobook={audiobook}
        currency={currency}
        darkMode={darkMode}
        linkReplace={linkReplace}
        reviews={reviews}
      />
    </>
  );
}

const getBreadcrumbs = ({ category, catalog }: { category: Category; catalog: Catalog }) => {
  const breadcrumbs: BreadCrumbsItem[] = [
    {
      name: category.name,
      link: {
        route: "category",
        params: {
          id: category.slug || category.id,
        },
      },
    },
  ];

  if (category._links["app:parent-category"]) {
    const parentCategoryId = category._links["app:parent-category"].href.match(/\/categories\/(.*?)\?/)?.[1] || "";
    const parentCategory = getCategoryFromCatalog(catalog, parentCategoryId);

    if (parentCategory) {
      breadcrumbs.unshift({
        name: parentCategory.name,
        link: {
          route: "category",
          params: {
            id: parentCategory.slug || parentCategory.id,
          },
        },
      });
    }
  }

  return breadcrumbs;
};

export const getServerSideProps = async ({ query }) => {
  let apiQueryId = query.id;

  if (!isId(apiQueryId)) {
    // Reference ID always starts with language and underscore.
    apiQueryId = /^[a-z]{2}_/i.test(apiQueryId) ? `reference-id/${apiQueryId}` : `slug/${apiQueryId}`;
  }

  try {
    const { catalogId, currency } = getLocationInfo(query.locale);
    const audiobook = await getAudiobook(apiQueryId, catalogId);

    if (!audiobook || !audiobook.id) {
      return {
        notFound: true,
        props: {},
      };
    }

    const [catalog, reviews, attachmentList] = await Promise.all([
      getScreenCatalog(catalogId),
      getProductReviewList(catalogId, audiobook.id),
      getAttachmentList(audiobook.id),
    ]);

    const productId = audiobook.slug || audiobook.id;

    if (query.id !== productId) {
      return {
        redirect: {
          permanent: true,
          destination: buildLink("audiobook", query.locale, { id: productId }),
        },
      };
    }

    const category = getCategoryFromCatalog(catalog, audiobook.main_category_id);

    return {
      props: {
        attachmentList: attachmentList || null,
        audiobook,
        reviews,
        darkMode: false,
        categoryName: category ? category.name : "",
        currency,
        breadcrumbs: category ? getBreadcrumbs({ category, catalog }) : [],
      },
    };
  } catch (error) {
    if (error.response?.status === 404) {
      return {
        notFound: true,
        props: {},
      };
    }

    Sentry.captureException(error);
    throw error;
  }
};
