import React from "react";
import { FirstPath, SecondPath, Svg, ThirdPath } from "./styled";

export type SpinnerColor = "tricolor" | string;

interface SpinnerProps {
  color?: SpinnerColor;
  size?: number;
}

const Spinner = ({ color = "tricolor", size = 60 }: SpinnerProps) => (
  <Svg color={color} size={size} viewBox="0 0 24 24">
    <FirstPath d="M23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23" />
    <SecondPath d="M5,12 C5,15.8659932 8.13400675,19 12,19 C15.8659932,19 19,15.8659932 19,12 C19,8.13400675 15.8659932,5 12,5" />
    <ThirdPath d="M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12" />
  </Svg>
);

export default Spinner;
