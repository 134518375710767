import React from "react";
import styled from "styled-components";

import { Audiobook } from "resources/AudiotekaApi";

import { DurationRow, TagsRow } from "./Rows";

const Container = styled.div`
  color: #fff;
  display: block;
`;

const Table = styled.table`
  --vertical-margin: 16px;
  margin-top: var(--vertical-margin);
  margin-bottom: var(--vertical-margin);

  td {
    line-height: 28px;
    padding: 3px;
    vertical-align: top;

    &:first-child {
      padding-right: 15px;
    }
  }
`;

interface Props {
  audiobook: Audiobook;
}

export const Details = ({ audiobook }: Props) => {
  const duration = audiobook.duration ?? 0;
  const lectors = audiobook._embedded["app:lector"];
  const publishers = audiobook._embedded["app:publisher"];
  const categories = audiobook._embedded["app:category"];
  const translators = audiobook._embedded["app:translator"];

  return (
    <Container>
      <Table>
        <tbody>
          <TagsRow tags={lectors} tagName="lector" />
          <TagsRow tags={translators} tagName="translator" />
          <DurationRow duration={duration} />
          <TagsRow tags={publishers} tagName="publisher" />
          <TagsRow tags={categories} tagName="category" />
        </tbody>
      </Table>
    </Container>
  );
};
