/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";

import { AlertProps } from "./types";

import Paper from "../Paper";

export const PaperStyled = styled(Paper)<AlertProps>`
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  padding: 16px;
  ${({ centered, mb = 0, mt = 0 }) => css`
    margin-bottom: ${mb}rem;
    margin-top: ${mt}rem;
    text-align: ${centered ? "center" : "left"};
  `}
`;
