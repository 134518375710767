import React from "react";
import styled from "styled-components";
import { useTranslations } from "next-intl";

import BaseButton, { ButtonVariant } from "modules/DesignSystem/components/Button";
import { breakpoint } from "modules/DesignSystem/breakpoint";

import { StarIcon } from "./StarIcon";

const Button = styled(BaseButton)`
  display: flex;
  align-items: center;
  width: 130px;
  padding: 0 !important;
  background: transparent;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: none;
  text-align: left;
  transition: color 0.25s ease;

  &:hover {
    background: transparent !important;
    color: #33adff;
  }

  @media (min-width: ${breakpoint.laptop}) {
    width: auto;
    white-space: nowrap;
  }
`;

const Text = styled.span`
  @media (min-width: ${breakpoint.tablet}) {
    flex: 1;
  }
`;

const MobileText = styled(Text)`
  @media (min-width: ${breakpoint.laptop}) {
    display: none;
  }
`;

const DesktopText = styled(Text)`
  display: none;

  @media (min-width: ${breakpoint.laptop}) {
    display: inline;
  }
`;

export const RatingButton = ({ rating, onReviews }: { rating: number; onReviews: () => void }) => {
  const t = useTranslations();

  return (
    <Button onClick={onReviews} variant={ButtonVariant.transparent}>
      <MobileText>{t("product.average_rating")}</MobileText>
      <DesktopText>{t("product.average_rating_short")}</DesktopText>
      <StarIcon rating={rating} />
    </Button>
  );
};
