import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";

import { ALERT_THEME } from "./consts";
import { PaperStyled } from "./styled";
import { AlertProps, AlertType } from "./types";

const Alert: FC<PropsWithChildren<AlertProps>> = ({
  alertType = AlertType.Info,
  elevation = 1,
  message,
  children,
  ...props
}) => {
  return (
    <PaperStyled theme={ALERT_THEME[alertType]} elevation={elevation} {...props}>
      {message || children}
    </PaperStyled>
  );
};

export default styled(Alert)`
  a {
    color: #33adff;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: #fff;
    }
  }
`;
