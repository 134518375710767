import React from "react";
import styled from "styled-components";
import { useTranslations } from "next-intl";

import { Person } from "resources/AudiotekaApi";

import { Tag } from "../ProductTop.styled";

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
`;

type Props =
  | {
      tags: Person[];
      tagName: "author" | "lector" | "publisher" | "category";
    }
  | {
      tags: Omit<Person, "slug">[];
      tagName: "translator";
    };

export const TagsRow = ({ tags, tagName }: Props) => {
  const t = useTranslations();

  // return null if the backend returns an array with a dummy object
  if (tags.length === 0 || (tags.length === 1 && tags[0].name === "")) return null;

  return (
    <tr>
      <td>{t(`product.${tagName}`)}</td>
      <td>
        {tagName === "translator" ? (
          tags.map((tag) => tag.name).join(", ")
        ) : (
          <TagsWrapper>
            {tags.map((tag) => (
              <Tag route={tagName} params={{ id: tag.slug || tag.id }} key={tag.id}>
                {tag.name}
              </Tag>
            ))}
          </TagsWrapper>
        )}
      </td>
    </tr>
  );
};

export const DurationRow = ({ duration }: { duration: number }) => {
  const t = useTranslations();

  if (duration <= 0) return null;

  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  const hoursText = t("product.duration_hours", { count: hours });
  const minutesText = t("product.duration_minutes", { count: minutes });

  return (
    <tr>
      <td>{t("product.duration")}</td>
      <td>{hours > 0 ? `${hoursText} ${minutesText}` : minutesText}</td>
    </tr>
  );
};
