import React from "react";
import styled from "styled-components";

import { Audiobook } from "resources/AudiotekaApi";
import { Grid, Link } from "components";
import { breakpoint } from "modules/DesignSystem/breakpoint";

import { PlayButton } from "./PlayButton/PlayButton";
import { RatingButton } from "./ActionButtons/RatingButton";
import { FavouritesButton } from "./ActionButtons/FavouritesButton";
import { Actions, Cover, Title } from "./ProductTop.styled";
import { MoreInfo } from "./MoreInfo";

interface Props {
  audiobook: Audiobook;
  available: boolean;
  canListen: boolean;
  onReviews: () => void;
}

const GridContainer = styled(Grid.Container)`
  isolation: isolate;
`;

const GridRow = styled(Grid.Row)`
  padding-top: 32px;
  padding-bottom: 32px;
`;

const Authors = styled.div`
  text-align: center;
  margin-bottom: 16px;

  @media (min-width: ${breakpoint.laptop}) {
    text-align: left;
    margin-bottom: 0;
  }
`;

const Author = styled(Link)`
  color: #fff;
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
`;

const Coma = styled.span`
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
`;

const DetailsColumn = styled(Grid.Column)`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoint.laptop}) {
    margin-top: 0;
  }
`;

export const ProductTop = ({ audiobook, available, canListen, onReviews }: Props) => {
  const authors = audiobook._embedded["app:author"];

  return (
    <GridContainer>
      <GridRow id="product-top">
        <Grid.Column span={{ _: 10, tablet: 4, laptop: 3 }} $offset={{ _: 2, tablet: 5, laptop: 1 }}>
          <Cover alt={audiobook.name} src={`${audiobook.image_url}?w=300&auto=format`} />
          {available && <PlayButton audiobook={audiobook} canListen={canListen} />}
        </Grid.Column>
        <DetailsColumn span={{ tablet: 6, laptop: 5, desktop: 6 }} $offset={{ tablet: 4, laptop: "auto" }}>
          <Title>{audiobook.name}</Title>
          <Authors>
            {authors.map((author, index) => {
              const isLast = index === authors.length - 1;
              return (
                <React.Fragment key={author.id}>
                  <Author route="author" params={{ id: author.slug || author.id }}>
                    {author.name}
                  </Author>
                  {isLast ? "" : <Coma>, </Coma>}
                </React.Fragment>
              );
            })}
          </Authors>

          {available && (
            <Actions>
              <RatingButton rating={Math.floor(audiobook.rating * 10) / 10} onReviews={onReviews} />
              <FavouritesButton audiobookId={audiobook.id} />
            </Actions>
          )}

          <MoreInfo audiobook={audiobook} available={available} />
        </DetailsColumn>
      </GridRow>
    </GridContainer>
  );
};
