import styled from "styled-components";

export const Review = styled.p`
  font-size: 16px;
  grid-column: 1/3;
  grid-row: 2;
  margin: 0;
  text-align: justify;
  word-break: break-word;
`;

export const ReviewDate = styled.div<{ accent?: boolean }>`
  color: ${({ accent }) => (accent ? "currentColor" : "#8d889e")};
  font-size: 12px;
  grid-column: 2;
  grid-row: 1;
`;

export const Root = styled.div<{ accent?: boolean }>`
  color: ${({ accent }) => (accent ? "#380089" : "currentColor")};
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
`;

export const Signature = styled.p<{ accent?: boolean }>`
  color: ${({ accent }) => (accent ? "currentColor" : "#8d889e")};
  font-size: 12px;
  grid-column: 1/3;
  grid-row: 3;
  margin: 0;
`;

export const Stars = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  grid-gap: 3px;
  grid-row: 1;
`;
