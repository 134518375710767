import styled from "styled-components";

import { breakpoint } from "modules/DesignSystem/breakpoint";
import Button from "modules/DesignSystem/components/Button";

export const AddRateBtn = styled(Button)`
  background-color: #e8e7ec;
  color: #000;
  font-size: 14px;
  height: 48px;
  width: 100%;

  &:hover {
    background-color: #d2d2d9 !important;
  }
`;

export const Rate = styled.div<{ inactive: boolean; wide: boolean }>`
  color: ${({ inactive }) => (inactive ? "#989898" : "#000")};
  font-size: ${({ inactive }) => (inactive ? "12px" : "14px")};
  grid-column: ${({ wide }) => (wide ? "1/3" : 2)};
  grid-row: ${({ wide }) => (wide ? 2 : 1)};

  a {
    color: #33adff;
    font-weight: 700;
  }

  p {
    margin: 0;
  }

  @media (min-width: ${breakpoint.tablet}) {
    grid-column: 2;
    grid-row: 1;
    text-align: right;
  }
`;

export const Rating = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoint.tablet}) {
    justify-content: flex-start;
  }
`;

export const Reviews = styled.div``;

export const ReviewsInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  max-width: 490px;

  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 14px;
  }
`;

export const ReviewsMore = styled.span`
  color: #380089;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin-top: 24px;
  text-decoration: underline;
  width: fit-content;
`;

export const Root = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  padding-top: 14px;

  @media (min-width: ${breakpoint.tablet}) {
    grid-gap: 30px 10px;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  grid-column: 1;
  grid-row: 1;
  margin: 0;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 24px;
  }
`;

export const Top = styled.div`
  align-items: center;
  display: grid;
  gap: 16px 10px;
  grid-template-columns: 1fr 195px;
  justify-content: space-between;

  @media (min-width: ${breakpoint.tablet}) {
    grid-template-columns: 1fr 343px;
  }
`;

export const SpinerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
