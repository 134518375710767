import React from "react";

import { ProductReviewList } from "resources/AudiotekaApi";

import ReviewItem from "../ReviewItem/ReviewItem";
import { Root } from "./styled";

const ReviewsList = ({ limit, reviews, ...props }: { limit?: number; reviews: ProductReviewList }) => {
  const list = reviews?._embedded?.["app:user-product-review"] || [];
  const reviewList = typeof limit === "number" ? list.slice(0, limit) : list;

  return (
    <Root {...props}>
      {reviewList.map((item) => (
        <ReviewItem key={item._embedded["app:review"]?.id} reviewItem={item} />
      ))}
    </Root>
  );
};

export default ReviewsList;
