import React from "react";
import styled from "styled-components";

import { NewIcon as BaseIcon } from "components/NewIcon";
import Tooltip from "modules/DesignSystem/components/Tooltip";

const Icon = styled(BaseIcon)`
  color: #bbb7c5;
  cursor: help;
  min-width: 20px;
  margin-left: var(--column-gap);
`;

export const InfoIcon = ({ info }: { info: string }) => {
  const infoRef = React.useRef<SVGSVGElement>();

  return (
    <>
      <Icon icon="info" width="20" height="20" iconRef={infoRef} />
      <Tooltip trigger={infoRef}>{info}</Tooltip>
    </>
  );
};
